import { render, staticRenderFns } from "./y_upload_wx_substitute_cert.vue?vue&type=template&id=65fcc73f&scoped=true"
import script from "./y_upload_wx_substitute_cert.vue?vue&type=script&lang=js"
export * from "./y_upload_wx_substitute_cert.vue?vue&type=script&lang=js"
import style1 from "./y_upload_wx_substitute_cert.vue?vue&type=style&index=1&id=65fcc73f&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65fcc73f",
  null
  
)

export default component.exports